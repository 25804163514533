<template>
  <div>
    <div @click="hideAllDropdown">
      <BreadCrumb
        title="Legal Research"
        subtitle="Case Law Search / Search Result / Summary"
      />

      <div class="main-summary-container">
        <div class="main-summary-left">
          <div class="main-navigation mt-4">
            <div class="d-flex align-items-center left" @click="goBack">
              <span class="material-symbols-rounded me-2">arrow_back</span>
              <p class="mb-0">Back</p>
            </div>
            <div v-if="activeTab === 'CaseSummary'" id="export-btn" class="d-flex align-items-center right">
              <p class="mb-0">Export</p>
              <span class="material-symbols-rounded">keyboard_arrow_down</span>
            </div>
            <b-tooltip
              target="export-btn"
              triggers="click"
              placement="bottom"
              id="export_box"
              :custom-class="`DROPDOWN-MENU`"
            >
              <div style="padding: 10px">
                <div
                  v-for="(item, index) in dropdownItems"
                  :key="index"
                  class="dropdown-option"
                  style="display: flex; align-items: center"
                >
                  <!-- Replace the standard checkbox with the CheckBox component -->
                  <CheckBox :set="selectedExportOptions.includes(item)" @input="toggleExport($event, item)" />
                  <label :for="`option-${index}`" style="margin-left: 8px">{{
                    item
                  }}</label>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <button @click="exportData" class="d-flex right">
                    <span class="material-symbols-rounded me-2">
                      ios_share
                    </span>
                    <p class="mb-0">Export</p>
                  </button>
                </div>
              </div>
            </b-tooltip>
          </div>

          <div class="main-container d-flex mt-4">
            <div class="navigation">
              <div
                :class="['link', { active: activeTab === 'CaseSummary' }]"
                @click="activeTab = 'CaseSummary'"
              >
                <p class="mb-3">Case Summary</p>
              </div>
              <div
                :class="['link', { active: activeTab === 'CompleteCase' }]"
                @click="activeTab = 'CompleteCase'"
              >
                <p class="mb-3">Complete Case</p>
              </div>
              <div
                :class="['link', { active: activeTab === 'Precedents' }]"
                @click="activeTab = 'Precedents'"
              >
                <p class="mb-3">Precedents</p>
              </div>
              <div
                :class="['link', { active: activeTab === 'Annotation' }]"
                @click="activeTab = 'Annotation'"
              >
                <p class="mb-3">Annotation</p>
              </div>
              <div
                :class="['link', { active: activeTab === 'CaseHistory' }]"
                @click="activeTab = 'CaseHistory'"
              >
                <p class="mb-3">Case History</p>
              </div>
            </div>

            <template v-if="activeTab === 'CaseSummary'">
              <article class="case-summary-content" v-if="pageloading">
                <Loader />
              </article>
              <article class="case-summary-content" v-else>
                <h4 class="case-title">{{ DATA.name || "Title" }}</h4>
                <section class="case-info">
                  <h4 class="section-header">Case Info</h4>

                  <div class="info-card">
                    <h5 class="info-title">Case Title</h5>
                    <p class="info-content">{{ DATA.name || "Title" }}</p>
                  </div>
                  <div class="info-card">
                    <h5 class="info-title">Case Number</h5>
                    <p class="info-content">
                      {{ basicDetails.case_number || "" }}
                    </p>
                  </div>
                  <div class="info-card">
                    <h5 class="info-title">Topic</h5>
                    <p class="info-content">{{ DATA.topic || "Topic" }}</p>
                  </div>

                  <!-- Judgement Info Section -->
                  <div class="judgement-info">
                    <h4 class="section-header">Judgement Info</h4>
                    <div class="judgement-details">
                      <div class="decision-date">
                        <p class="date-day">{{ formattedDate.day }}</p>
                        <p class="date-month">{{ formattedDate.date }}</p>
                        <p class="date-label">Decision Date</p>
                      </div>
                      <div class="jurisdiction-details">
                        <div class="jurisdiction-card">
                          <h5 class="info-title">Jurisdiction</h5>
                          <p class="info-content">{{ DATA.type || "USA" }}</p>
                        </div>
                        <div class="court-card">
                          <h5 class="info-title">Court Name</h5>
                          <p class="info-content">
                            {{ DATA.court || "Court Name" }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Party Information -->
                  <div class="party-info">
                    <div class="petitioner-info">
                      <h5 class="info-title">Petitioner</h5>
                      <p class="info-content">
                        {{ basicDetails.petitioner || "Petitioner" }}
                      </p>
                    </div>
                    <div class="petitioner-case">
                      <h5 class="info-title">Petitioner's Case</h5>
                      <p class="info-content">
                        {{
                          basicDetails.petitioner_case ||
                          "Petitioner's Case Details"
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="party-info">
                    <div class="respondent-info">
                      <h5 class="info-title">Respondent</h5>
                      <p class="info-content">
                        {{ basicDetails.respondent || "Respondent" }}
                      </p>
                    </div>
                    <div class="respondent-claim">
                      <h5 class="info-title">Respondent's Claim</h5>
                      <p class="info-content">
                        {{
                          basicDetails.respondent_claims ||
                          "Respondent's Claim Details"
                        }}
                      </p>
                    </div>
                  </div>

                  <!-- Judgement Result -->
                  <div class="judgement-result">
                    <h5 class="info-title">Judgement</h5>
                    <p class="info-content">
                      {{ basicDetails.judgement || "Judgement Details" }}
                    </p>
                  </div>
                </section>

                <!-- Case Summary Section -->
                <section class="case-summary">
                  <header class="summary-header">
                    <h2 class="section-header">Summary</h2>
                    <button
                      class="copy-button"
                      aria-label="Copy summary"
                      @click="copyContent"
                    >
                      <span class="material-symbols-rounded copy-icon"
                        >content_copy</span
                      >
                      <span class="button-text">Copy</span>
                    </button>
                  </header>

                  <div class="summary-content">
                    <!-- Brief Summary -->
                    <template v-if="brief_summary">
                      <div class="d-flex">
                        <h5 class="summary-title">Brief Summary</h5>
                        <button
                          class="expand-button"
                          aria-label="Expand summary"
                        >
                          <!-- <span class="material-symbols-rounded expand-icon">text_to_speech</span> -->
                          <span
                            v-b-tooltip.hover.top="{ variant: 'secondary' }"
                            :title="isSpeaking ? 'Pause' : 'Audio Summary'"
                            @click="toggleSpeech(brief_summary)"
                            class="material-symbols-rounded expand-icon"
                          >
                            {{ isSpeaking ? "pause" : "text_to_speech" }}
                          </span>
                        </button>
                      </div>
                      <p class="summary-text">{{ brief_summary }}</p>
                    </template>

                    <!-- Key Points -->
                    <template v-if="key_points">
                      <h5 class="summary-title">Key Points</h5>
                      <ul class="key-points-list">
                        <li
                          class="key-point"
                          v-for="(point, index) in key_points"
                          :key="index"
                        >
                          <p>
                            {{ index + 1 }}.<span>{{ point }}</span>
                          </p>
                          <button
                            class="expand-button"
                            aria-label="Copy key point"
                          >
                            <!-- <span class="material-symbols-rounded expand-icon">text_to_speech</span> -->
                            <span
                              v-b-tooltip.hover.left="{ variant: 'secondary' }"
                              :title="
                                buttonStates[index].isSpeaking
                                  ? 'Pause'
                                  : 'Audio'
                              "
                              @click="toggleSpeechKeyPoints(point, index)"
                              class="material-symbols-rounded expand-icon"
                            >
                              {{
                                buttonStates[index].isSpeaking
                                  ? "pause"
                                  : "text_to_speech"
                              }}
                            </span>
                          </button>
                        </li>
                      </ul>
                    </template>
                  </div>
                </section>
              </article>
            </template>

            <template v-else-if="activeTab === 'CompleteCase'">
              <article class="case-summary-content">
                <h4 class="case-title">{{ DATA.name ?? "" }}</h4>
                <p
                  v-if="DATA.full_content.type === 'html'"
                  class="m-2 border-bottom mb-3"
                  v-html="DATA.full_content.value"
                ></p>
                <iframe
                  v-else-if="DATA.full_content.type === 'pdf'"
                  class="border-bottom mb-3"
                  :src="DATA.full_content.value"
                  height="600px"
                  width="100%"
                  frameborder="0"
                ></iframe>

                <!-- Disclaimer -->
                <p class="font-10 text-muted">
                  NexLaw does not hold any copyright for the case content. All
                  content displayed remains the property of its original source.
                </p>
              </article>
            </template>

            <template v-else-if="activeTab === 'Precedents'">
              <div v-if="mappedPrecedents && mappedPrecedents.length > 0">
                <article
                  class="case-summary-content"
                  v-if="!showPrecedent && showContent == null"
                >
                  <h4 class="case-title">{{ DATA.name ?? "" }}</h4>
                  <button class="d-flex report mt-4" @click="togglePrecedent">
                    <span class="material-symbols-rounded me-2">article</span>
                    Precedent Report
                  </button>
                  <CaseList
                    caseType="Precedents"
                    v-if="mappedPrecedents && mappedPrecedents.length > 0"
                    :filtered_searched_data="mappedPrecedents"
                  />
                </article>
                <article
                  class="case-summary-content"
                  v-if="showPrecedent && showContent == null"
                >
                  <h4 class="case-title mb-4">{{ DATA.name ?? "" }}</h4>
                  <p class="pre-title" @click="backPrecedent">
                    Back to Precedent Lists
                  </p>
                  <h4 class="case-title mb-4">Precedent Report</h4>

                  <template v-if="similarCasesList.length !== 0">
                    <h4 class="case-title">Top Similar Cases</h4>
                    <div class="">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" width="4%">{{ "ID" }}</th>
                            <th scope="col" width="40%">{{ "Precedent" }}</th>
                            <th scope="col" width="20%">{{ "Court" }}</th>
                            <th scope="col" width="13%">{{ "Date" }}</th>
                            <th scope="col" width="13%">{{ "Country" }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <div
                            v-if="similarCasesList.length === 0"
                            class="container_margin"
                          >
                            <div class="mb-2 m-auto" style="width: 200px">
                              <img
                                src="@/assets/misc/empty.svg"
                                alt="No Data Image"
                              />
                            </div>
                            <p class="text-center text-blue font-weight-bold">
                              {{ "No Precedents Data" }}
                            </p>
                          </div>

                          <tr
                            v-else
                            v-for="(item, index) in paginatedPrecedents1"
                            :key="index"
                            @click="getSimilarOriginalIndex(item)"
                          >
                            <td width="4%">
                              <p>{{ getContinuousId1(index) }}</p>
                            </td>
                            <td width="40%">
                              <p
                                class="ellipsis"
                                @mouseover="toggleEllipse($event)"
                                @mouseleave="toggleEllipse($event)"
                              >
                                {{ item.Precedent || item.Precedents }}
                              </p>
                            </td>
                            <td width="20%">
                              <p
                                class="ellipsis"
                                @mouseover="toggleEllipse($event)"
                                @mouseleave="toggleEllipse($event)"
                              >
                                {{ item.Court }}
                              </p>
                            </td>
                            <td width="13%">
                              <p class="text-muted font-weight-bold">
                                {{
                                  item.JudgementDate !== "None"
                                    ? $options.filters.formatDateLoc(
                                        item.JudgementDate
                                      )
                                    : "None"
                                }}
                              </p>
                            </td>
                            <td width="13%">
                              <p>{{ item.Country }}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                  <template>
                    <div
                      v-if="analysis_dateRangeList.length !== 0"
                      class="my-4 p-4 card border shadow-sm"
                    >
                      <h5 class="font-weight-bold mb-1">
                        {{ "Temporal Distribution of Cases: Count over Time" }}
                      </h5>
                      <small class="font-12 mb-3">
                        The graph visually represents the distribution of cases
                        over a specific period
                      </small>

                      <apexchart
                        v-if="analysis_dateRangeList.length > 0"
                        :options="dateChartOptions"
                        :series="dateChartSeries"
                        height="450"
                      ></apexchart>

                      <!-- Display a message if no data is available -->
                      <template v-else>
                        <div class="mt-4 mb-2 m-auto" style="width: 200px">
                          <img
                            src="@/assets/misc/empty.svg"
                            alt="No Data Image"
                          />
                        </div>
                        <p class="text-center text-blue">
                          <b>{{ "No Case" }}</b>
                        </p>
                      </template>
                    </div>
                  </template>
                  <div
                    v-if="analysis_statueList.length !== 0"
                    class="my-4 p-4 card border shadow-sm"
                  >
                    <h5 class="mb-1">
                      <b>{{ "Top 10 Statutes Count" }}</b>
                    </h5>
                    <small class="font-12 mb-3">
                      The chart provides a visual representation of the statutes
                      referenced in a collection of cases, along with the
                      frequency of their mentions
                    </small>

                    <apexchart
                      v-if="analysis_statueList.length > 0"
                      type="bar"
                      :options="statueChartOptions"
                      :series="statueChartSeries"
                      height="450"
                    ></apexchart>

                    <!-- Display a message if no data is available -->
                    <template v-else>
                      <div class="mt-4 mb-2 m-auto" style="width: 200px">
                        <img
                          src="@/assets/misc/empty.svg"
                          alt="No Data Image"
                        />
                      </div>
                      <p class="text-center text-blue">
                        <b>{{ "No Case" }}</b>
                      </p>
                    </template>
                  </div>

                  <div
                    v-if="analysis_countryList.length !== 0"
                    class="mb-4 p-4 card border shadow-sm"
                  >
                    <h5 class="mb-1">
                      <b>{{ "Geographical Distribution of Cases" }}</b>
                    </h5>
                    <small class="font-12 mb-3">
                      The donut chart shows the distribution of cases based on
                      the countries in which they have occurred
                    </small>

                    <apexchart
                      v-if="analysis_countryList.length > 0"
                      type="donut"
                      :options="countryChartOptions"
                      :series="countryChartSeries"
                      height="450"
                    ></apexchart>

                    <template v-else>
                      <div class="mt-4 mb-2 m-auto" style="width: 200px">
                        <img
                          src="@/assets/misc/empty.svg"
                          alt="No Data Image"
                        />
                      </div>
                      <p class="text-center text-blue">
                        <b>{{ "No Case" }}</b>
                      </p>
                    </template>
                  </div>
                  <div class="row mb-3">
                    <div class="col d-flex align-items-center">
                      <h5 class="mb-0">
                        <b>{{ "All Precedents" }}</b>
                      </h5>
                    </div>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" width="4%">{{ "ID" }}</th>
                        <th scope="col" width="40%">{{ "Precedent" }}</th>
                        <th scope="col" width="20%">{{ "Court" }}</th>
                        <th scope="col" width="13%">{{ "Date" }}</th>
                        <th scope="col" width="13%">{{ "Country" }}</th>
                      </tr>
                    </thead>

                    <tbody class="mb-3">
                      <template v-if="all_precedents.length === 0">
                        <div class="mt-4 mb-2">
                          <img
                            src="@/assets/misc/empty.svg"
                            alt="No Data Image"
                            class="no-data"
                          />
                        </div>
                        <p class="text-center text-blue font-weight-bold">
                          {{ "No Precedents Data" }}
                        </p>
                      </template>

                      <tr
                        v-else
                        :key="index"
                        v-for="(item, index) in paginatedPrecedents2"
                        @click="showContent = index"
                      >
                        <td width="7%">
                          <p>{{ getContinuousId2(index) }}</p>
                        </td>
                        <td width="40%">
                          <p
                            class="ellipsis"
                            :class="{ expanded: item.showPrecedent }"
                            @click="toggleShow(item, 'showPrecedent')"
                          >
                            {{ item.title }}
                          </p>
                        </td>
                        <td width="20%">
                          <p
                            class="ellipsis"
                            :class="{ expanded: item.showCourt }"
                            @click="toggleShow(item, 'showCourt')"
                          >
                            {{ item.court }}
                          </p>
                        </td>
                        <td width="13%">
                          <p class="text-muted font-weight-bold">
                            {{
                              item.judgement_date !== "None"
                                ? $options.filters.formatDateLoc(
                                    item.judgement_date
                                  )
                                : "None"
                            }}
                          </p>
                        </td>
                        <td width="13%">
                          <p>{{ item.location }}</p>
                        </td>
                      </tr>
                    </tbody>

                    <div
                      v-if="all_precedents.length > 5"
                      class="col"
                      style="float: right"
                    >
                      <b-pagination
                        class="mb-0 float-right"
                        v-model="currentPage2"
                        :total-rows="all_precedents.length"
                        :per-page="perPage"
                        aria-controls="my-table2"
                        last-number
                      ></b-pagination>
                    </div>
                  </table>
                </article>
                <article class="case-summary-content" v-if="showContent != null">
                  <h4 class="case-title">{{DATA.name ?? "Title"}}</h4>
                  <p class="pre-title" @click="backPrecedentList">Back to Precedent Lists</p>
                  <h4 class="case-title" style="color:grey !important;" v-html="all_precedents[showContent].title"></h4>
                  <h4 class="case-title">Full Case</h4>
                  <p
                    v-if="all_precedents[showContent].full_content.type === 'html'"
                    class="m-2 border-bottom mb-3"
                    v-html="all_precedents[showContent].full_content.value"
                    ></p>
                    <iframe
                    v-else-if="all_precedents[showContent].full_content.type === 'pdf'"
                    class="border-bottom mb-3"
                    :src="all_precedents[showContent].full_content.value"
                    height="600px"
                    width="100%"
                    frameborder="0"
                    ></iframe>
                    <p class="font-10 text-muted">
                    NexLaw does not hold any copyright for the case content. All content displayed remains the property of its original source.
                    </p>
                </article>
              </div>
              <article class="case-summary-content" v-else>
                <h4 class="case-title">{{ DATA.name || "Title" }}</h4>
                <main class="case-content">
                  <section class="case-info text-center">
                    <img
                      :src="emptyImg"
                      alt="Precedent icon"
                      class="case-icon"
                    />
                    <p style="font-weight: 500">No Precedent Available</p>
                    <p style="font-weight: 500">
                      We couldn't find any precedent for the selected matter.
                      Try searching for a different case or verify the case
                      details.
                    </p>
                  </section>
                </main>
              </article>
            </template>

            <template v-else-if="activeTab === 'Annotation'">
              <article
                class="case-summary-content"
                v-if="
                  Object.keys(annotations).length && showFullContent == null
                "
              >
                <h4 class="case-title">{{ DATA.name || "Title" }}</h4>

                <div
                  v-for="(KEY, index) in Object.keys(annotations)"
                  :key="index"
                >
                  <article
                    @click="toggleExpand(index)"
                    :class="[
                      'case-card',
                      { highlighted: expandedItems[index] },
                    ]"
                  >
                    <span class="case-number">{{ index + 1 }}</span>
                    <div class="case-info-anno">
                      <div class="case-content-anno">
                        <div class="case-header">
                          <p class="case-title" style="font-style: italic">
                            {{ KEY.replaceAll('"', "") }}
                          </p>
                          <p class="cases">
                            {{ annotations[KEY].caselaw.length }} case{{
                              annotations[KEY].caselaw.length !== 1 ? "s" : ""
                            }}
                          </p>
                        </div>
                        <button
                          class="expand-btn"
                          aria-label="Expand case details"
                        >
                          <span
                            v-if="!expandedItems[index]"
                            class="material-symbols-rounded"
                            >keyboard_arrow_down</span
                          >
                          <span v-else class="material-symbols-rounded"
                            >keyboard_arrow_up</span
                          >
                        </button>
                      </div>
                    </div>
                  </article>

                  <section
                    v-show="expandedItems[index]"
                    class="case-details mb-4"
                  >
                    <header>
                      <h4 class="details-title mt-3">
                        {{ KEY.replaceAll('"', "") }}
                      </h4>
                      <p class="cases-color mt-3">
                        {{ annotations[KEY].caselaw.length }} case{{
                          annotations[KEY].caselaw.length !== 1 ? "s" : ""
                        }}
                      </p>
                      <h4 class="details-title">
                        Cases applying this legislation:
                      </h4>
                    </header>

                    <div
                      v-for="(obj, o_index) in annotations[KEY].caselaw"
                      :key="o_index"
                    >
                      <div class="details-content">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex">
                            <span class="me-2">{{ o_index + 1 }}.</span>
                            <h5 class="details-subtitle">
                              {{ obj.Name ?? "" }}
                            </h5>
                          </div>
                          <button
                            class="open-button"
                            @click="showFullContent = o_index"
                          >
                            Open
                          </button>
                        </div>
                        <p class="details-text" v-if="obj.detail">
                          {{ obj.detail ?? "" }}
                        </p>
                      </div>

                      <div class="topic-section" v-if="obj.topic">
                        <h5 class="topic-title">Topic</h5>
                        <p class="topic-text">{{ obj.topic ?? "" }}</p>
                      </div>

                      <div class="abstract-section" v-if="obj.abstract">
                        <h5 class="abstract-title">Abstract</h5>
                        <p class="abstract-text">{{ obj.abstract ?? "" }}</p>
                      </div>

                      <div class="jurisdiction-section">
                        <div class="jurisdiction-content"></div>
                      </div>

                      <footer class="case-footer">
                        <div class="decision-date" v-if="obj.Date">
                          <span class="date-month-year">{{ obj.Date }}</span>
                          <span class="date-label">Decision Date</span>
                        </div>
                        <div class="case-jurisdiction">
                          <div
                            class="jurisdiction-info d-flex"
                            v-if="obj.Jurisdiction"
                          >
                            <h6 class="jurisdiction-label me-1">Country:</h6>
                            <p class="jurisdiction-value">
                              {{ obj.Jurisdiction }}
                            </p>
                          </div>
                          <div class="court-info d-flex" v-if="obj.Court">
                            <h6 class="court-label me-1">Court:</h6>
                            <p class="court-value">
                              {{
                                obj.Court === "Not Stated" ? "N/A" : obj.Court
                              }}
                            </p>
                          </div>
                        </div>
                      </footer>
                    </div>
                  </section>
                </div>
              </article>
              <article
                class="case-summary-content"
                v-else-if="
                  Object.keys(annotations).length === 0 &&
                  showFullContent === null
                "
              >
                <h4 class="case-title">{{ DATA.name || "Title" }}</h4>
                <main class="case-content">
                  <section class="case-info text-center">
                    <img
                      :src="emptyImg"
                      alt="Annotaion icon"
                      class="case-icon"
                    />
                    <p style="font-weight: 500">No Annotation Available</p>
                    <p style="font-weight: 500">
                      We couldn't find any case history for the selected matter.
                      Try searching for a different case or verify the case
                      details.
                    </p>
                  </section>
                </main>
              </article>
              <article
                class="case-summary-content"
                v-if="showFullContent !== null"
              >
                <div>
                  <h4 class="case-title">{{ DATA.name || "Title" }}</h4>
                  <p class="pre-title" @click="backAnnotation">
                    Back to Annotation Lists
                  </p>
                  <h4 class="case-title">Full Case</h4>
                  <div
                    v-if="
                      annotations[Object.keys(annotations)[0]].caselaw[
                        showFullContent
                      ]
                    "
                  >
                    <p
                      v-if="
                        annotations[Object.keys(annotations)[0]].caselaw[
                          showFullContent
                        ].full_content.type === 'html'
                      "
                      v-html="
                        annotations[Object.keys(annotations)[0]].caselaw[
                          showFullContent
                        ].full_content.value
                      "
                    ></p>
                    <iframe
                      v-else-if="
                        annotations[Object.keys(annotations)[0]].caselaw[
                          showFullContent
                        ].full_content.type === 'pdf'
                      "
                      :src="
                        annotations[Object.keys(annotations)[0]].caselaw[
                          showFullContent
                        ].full_content.value
                      "
                      height="600px"
                      width="100%"
                      frameborder="0"
                    >
                    </iframe>
                    <p class="font-10 text-muted">
                      NexLaw does not hold any copyright for the case content.
                      All content displayed remains the property of its original
                      source.
                    </p>
                  </div>
                  <button @click="showFullContent = null">
                    Back to Summary
                  </button>
                </div>
              </article>
            </template>

            <template v-else-if="activeTab === 'CaseHistory'">
              <article class="case-summary-content" v-if="caseHistory.length">
                <h4 class="case-title">{{ DATA.name || "Title" }}</h4>

                <p class="case-title">Main Case</p>
                <main class="case-content">
                  <div>
                    <p
                      class="card p-3"
                      v-html="formatData(mainCase.analysis)"
                    ></p>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Case Details</th>
                        <th scope="col">Judgement Analysis</th>
                        <th scope="col">Comparison of Legal principle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(ch, id) in caseHistory" :key="id">
                        <td width="30%">
                          <b>Case Name</b>
                          <p>
                            <a :href="ch.Link" target="_blank">{{ ch.Name }}</a>
                          </p>
                          <b>Date</b>
                          <p>{{ ch.Date | formatDateLoc }}</p>
                          <b>Court</b>
                          <p>{{ ch.Court }}</p>
                        </td>
                        <td width="35%" v-html="formatData(ch.analysis)"></td>
                        <td width="35%" v-html="formatData(ch.comparison)"></td>
                      </tr>
                    </tbody>
                  </table>
                </main>
              </article>
              <article class="case-summary-content" v-else>
                <h4 class="case-title">{{ DATA.name || "Title" }}</h4>
                <section class="case-info text-center">
                  <img
                    :src="emptyImg"
                    alt="Case history icon"
                    class="case-icon"
                  />
                  <p style="font-weight: 500">No Case History Available</p>
                  <p style="font-weight: 500">
                    We couldn't find any case history for the selected matter.
                    Try searching for a different case or verify the case
                    details.
                  </p>
                </section>
              </article>
            </template>
          </div>
        </div>

        <div class="main-summary-right">
          <LawBot :id="Number(id)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../../components/misc/BreadCrumb.vue";
import CaseList from "../CaseList.vue";
import casesearch from "../../../store/casesearch";
import CheckBox from "../../../components/input/CheckBox.vue";
import LawBot from "./LawBot.vue";
import Loader from "../Loader.vue";
import emptyImg from "./../../../../src/assets/misc/empty.svg";
import { ExportData, sanitizeHTML, toggleEllipse } from "../../../store/utils";
import { BPagination } from "bootstrap-vue";
export default {
  components: {
    BreadCrumb,
    CaseList,
    LawBot,
    CheckBox,
    Loader,
    BPagination,
  },
  data() {
    return {
      id: this.$route.params.id,
      activeTab: "CaseSummary",
      dropdownItems: ["PDF", "Document"],
      selectedExportOptions: [],
      permissions: localStorage.permissions,
      pageloading: true,
      DATA: {},
      basicDetails: {},
      isSpeaking: false,
      brief_summary: "",
      key_points: [],
      buttonStates: [],
      annotations: {},
      mainCase: null,
      caseHistory: [],
      emptyImg,
      loop: false,
      isExpanded: false,
      showPrecedent: false,
      showContent: null,
      full_content: [],
      rows: 100,
      perPage: 5,
      currentPage1: 1,
      currentPage2: 1,
      page: 0,
      jurisID: 0,
      similarCasesList: [],
      analysis_caseList: [],
      analysis_countryList: [],
      analysis_courtsList: [],
      analysis_dateRangeList: [],
      analysis_statueList: [],
      all_precedents: [],
      chartOptions: {
        chart: { type: "bar", height: 450, width: 700 },
        xaxis: { categories: [], labels: { show: false } },
        yaxis: { tickAmount: 4, min: 0, max: 0 },
        colors: [
          "#d11141",
          "#00b159",
          "#00aedb",
          "#f37735",
          "#ffc425",
          "#a200ff",
          "#191d58",
          "#007b7b",
          "#a05000",
          "#8ec127",
        ],
        plotOptions: {
          bar: { horizontal: false, columnWidth: "40px", distributed: true },
        },
        dataLabels: { enabled: false },
        legend: { show: true, position: "right", width: 300 },
      },
      chartSeries: [{ name: "Count", data: [] }],

      statueChartOptions: {
        chart: { type: "bar", height: 450, width: 700 },
        xaxis: { categories: [], labels: { show: false } },
        yaxis: { tickAmount: 4, min: 0, max: 0, stepSize: 2 },
        colors: [
          "#d11141",
          "#00b159",
          "#00aedb",
          "#f37735",
          "#ffc425",
          "#a200ff",
          "#191d58",
          "#007b7b",
          "#a05000",
          "#8ec127",
        ],
        plotOptions: {
          bar: { horizontal: false, columnWidth: "40px", distributed: true },
        },
        dataLabels: { enabled: false },
        legend: { show: true, position: "right", width: 300 },
      },
      statueChartSeries: [{ name: "Count", data: [] }],

      dateChartOptions: {
        chart: { type: "area", height: 450, width: 700 },
        xaxis: {
          type: "datetime",
          categories: [],
          labels: { rotate: -75, style: { fontSize: "11px" } },
        },
        yaxis: { tickAmount: 0.5, min: 0, max: 0, stepSize: 2 },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [100],
          },
        },
        colors: ["#191d58"],
        plotOptions: {
          bar: { horizontal: false, columnWidth: "33px", distributed: true },
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        stroke: { curve: "smooth" },
      },
      dateChartSeries: [{ name: "Count", data: [] }],

      countryChartOptions: {
        chart: { type: "donut", height: 450, width: 700 },
        labels: [],
        colors: [
          "#d11141",
          "#00b159",
          "#00aedb",
          "#f37735",
          "#ffc425",
          "#a200ff",
          "#191d58",
          "#007b7b",
          "#a05000",
          "#8ec127",
        ],
        dataLabels: { enabled: true },
        legend: { show: true },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value + " case(s)";
            },
          },
        },
      },
      countryChartSeries: [],
      expandedItems: [],
      showFullContent: null,
    };
  },
  mounted() {
    this.expandedItems = Object.keys(this.annotations).map(() => false);
  },
  methods: {
    toggleEllipse,
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    formatData(data) {
      return data
        .replaceAll("\n", "<br>")
        .replace(/\*\*[\s\S]*?\*\*/g, (m) =>
          m.replace("**", "<b>").replace("**", "</b>")
        );
    },
    toggleExpand(index) {
      this.$set(this.expandedItems, index, !this.expandedItems[index]);
    },
    goBack() {
      this.$router.go(-1);
    },
    toggleSpeech(text, index) {
      const target = index == null ? this : this.buttonStates[index];

      if (!target.isSpeaking) {
        if (this.utterance?.text ?? null === text) {
          try {
            speechSynthesis.resume();
            target.isSpeaking = true;
          } catch (error) {
            this.initializeUtterance(text, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          target.isSpeaking = false;
          this.initializeUtterance(text, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        target.isSpeaking = false;

        this.utterance.onpause = () => {
          target.isSpeaking = false;
          this.utterance.onpause = null;
        };
      }
    },
    initializeUtterance(speechText, index = null) {
      const target = index == null ? this : this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        target.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        target.isSpeaking = false;
      };
    },
    toggleSpeechKeyPoints(text, index) {
      var speechText = text;
      const currentButtonState = this.buttonStates[index];
      if (!currentButtonState.isSpeaking) {
        if (
          this.utterance &&
          this.utterance.text !== "" &&
          this.utterance.text === speechText
        ) {
          try {
            speechSynthesis.resume();
            currentButtonState.isSpeaking = true;
          } catch (error) {
            this.initializeUtteranceKeyPoints(speechText, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          currentButtonState.isSpeaking = false;
          this.initializeUtteranceKeyPoints(speechText, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        currentButtonState.isSpeaking = false;

        this.utterance.onpause = () => {
          currentButtonState.isSpeaking = false;
          this.utterance.onpause = null;
        };
      }
    },
    initializeUtteranceKeyPoints(speechText, index) {
      const currentButtonState = this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        currentButtonState.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        currentButtonState.isSpeaking = false;
      };
    },
    toggleShow(item, field) {
      if (!Object.prototype.hasOwnProperty.call(item, field)) {
        this.$set(item, field, false);
      }
      item[field] = !item[field];
    },
    togglePrecedent() {
      this.showPrecedent = !this.showPrecedent;
    },
    backPrecedent() {
      this.showPrecedent = !this.showPrecedent;
    },
    backAnnotation() {
      this.showFullContent = null;
    },
    backPrecedentList() {
      this.showContent = null;
    },
    getSimilarOriginalIndex(item) {
      const precedent = item.Precedent ? item.Precedent : item.Precedents;
      const index = this.all_precedents.findIndex((element) => element.title === precedent);
      if (index !== -1) this.showContent = index;
    },
    toggleExport(e, item) {
      if (e) {
        if (!this.selectedExportOptions.includes(item)) this.selectedExportOptions.push(item);
      } else if (!e) {
        const index = this.selectedExportOptions.indexOf(item);
        this.selectedExportOptions.splice(index, 1);
      }
    },
    exportData() {
      if (!this.selectedExportOptions.length) {
        this.$toast.warn('Kindly select a format to export');
        return;
      }

      let contentToExport = '';
      if (this.brief_summary) contentToExport += `Brief Summary:\n${this.brief_summary}\n\n`;
      if (this.key_points && this.key_points.length > 0) {
        contentToExport += "Key Points:\n";
        this.key_points.forEach((point, index) => {
          contentToExport += `${index + 1}. ${point}\n`;
        });
      }

      let status = ExportData(contentToExport.replaceAll('\n', '<br>'), [
        this.selectedExportOptions.find((item) => item === 'Document'),
        this.selectedExportOptions.find((item) => item === 'PDF')
      ]);

      if (status[0]) this.$toast.success('Successfully downloaded Document');
      if (status[1]) this.$toast.success('Successfully downloaded PDF');

      this.selectedExportOptions = [];
    },
    updateCurrentPage(source) {
      this.currentPage1 = source.page1 ? parseInt(source.page1, 10) : 1;
      this.currentPage2 = source.page2 ? parseInt(source.page2, 10) : 1;
    },
    getContinuousId1(index) {
      return (this.currentPage1 - 1) * this.perPage + index + 1;
    },
    getContinuousId2(index) {
      return (this.currentPage2 - 1) * this.perPage + index + 1;
    },
    copyContent() {
      let contentToCopy = "";
      if (this.brief_summary) {
        contentToCopy += `Brief Summary:\n${this.brief_summary}\n\n`;
      }
      if (this.key_points && this.key_points.length > 0) {
        contentToCopy += "Key Points:\n";
        this.key_points.forEach((point, index) => {
          contentToCopy += `${index + 1}. ${point}\n`;
        });
      }
      navigator.clipboard
        .writeText(contentToCopy)
        .then(() => {
          this.$toast.success("Copied");
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    },
    setupCharts() {
      const first10Items = this.analysis_caseList.slice(0, 10);
      this.chartOptions.xaxis.categories = first10Items.map((entry) => entry.caseName);
      this.chartSeries[0].data = first10Items.map((entry) => entry.count);
      this.chartOptions.yaxis.max = Math.max(...first10Items.map((entry) => entry.count)) + 10;
        
      const top10Statue = this.analysis_statueList.slice(0, 10);
      this.statueChartOptions.xaxis.categories = top10Statue.map((entry) => entry.statue);
      this.statueChartSeries[0].data = top10Statue.map((entry) => entry.count);
      this.statueChartOptions.yaxis.max = Math.max(...top10Statue.map((entry) => entry.count)) + 2;
      
      const dateRange = this.analysis_dateRangeList.slice(0, 10);
      this.dateChartOptions.xaxis.categories = dateRange.map((entry) => entry.dateRange);
      this.dateChartSeries[0].data = dateRange.map((entry) => entry.count);
      this.dateChartOptions.yaxis.max = Math.max(...dateRange.map((entry) => entry.count)) + 2;
      
      const top10Countries = this.analysis_countryList.slice(0, 10);
      this.countryChartOptions.labels = top10Countries.map((entry) => entry.country);
      this.countryChartSeries = top10Countries.map((entry) => entry.count);
    }
  },
  computed: {
    formattedDate() {
      const dateString = this.DATA.date;
      if (!dateString) return { day: "", date: "" };
      const [year, month, day] = dateString.split("-");
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = months[parseInt(month, 10) - 1];
      return {
        day: day,
        date: `${monthName} ${year}`,
      };
    },
    mappedPrecedents() {
      return this.DATA.precedents.map((precedent) => ({
        id: precedent.id,
        name: precedent.title,
        court: precedent.court,
        type: precedent.location,
        completedPerCentage: 100,
        date: precedent.judgement_date,
        content: precedent.abstract,
        highlights: precedent.abstract,
      }));
    },
    paginatedPrecedents1() {
      return this.similarCasesList.slice(
        (this.currentPage1 - 1) * this.perPage,
        this.currentPage1 * this.perPage
      );
    },
    paginatedPrecedents2() {
      return this.all_precedents.slice(
        (this.currentPage2 - 1) * this.perPage,
        this.currentPage2 * this.perPage
      );
    },
    sanitizedHTML() {
      return sanitizeHTML(this.full_content.value);
    },
  },
  created() {
    const fetchCaseSummary = async () => {
      try {
        const response = await casesearch.OpenSummary(this.$route.params.id);
        const DATA = response.data.data;

        // Check if DATA.values is empty
        if (Array.isArray(DATA.values) && DATA.values.length > 0) {
          this.DATA = DATA;
          this.pageloading = false;

          // Process the received data
          const values = DATA.values;

          this.brief_summary = JSON.parse(
            values.find((el) => el.key === "brief_summary").value
          );

          this.key_points = JSON.parse(
            values.find((el) => el.key === "key_points").value
          );
          this.buttonStates = this.key_points.map(() => ({
            isSpeaking: false,
          }));

          const basicDetailsItem = values.find(
            (item) => item.key === "basic_details"
          );
          if (basicDetailsItem && basicDetailsItem.value) {
            const details = JSON.parse(basicDetailsItem.value);
            console.log(details);
            this.basicDetails = details[0]; // Update basic details state
          } else {
            console.warn("No basic details found in response.");
          }

          // Precedent
          this.jurisID = DATA.id;

          const similarCases = values.find(
            (el) => el.key === "precedents_similar"
          )?.value;
          this.similarCasesList = similarCases ? JSON.parse(similarCases) : [];
          console.log("Similar Cases:", this.similarCasesList);

          let precedentsAnalysis = values.find(
            (el) => el.key === "precedents_analysis"
          )?.value;
          precedentsAnalysis = precedentsAnalysis
            ? JSON.parse(precedentsAnalysis)["all"]
            : false;

          if (precedentsAnalysis) {
            this.analysis_caseList = Object.entries(
              precedentsAnalysis["case"]
            ).map(([caseName, count]) => ({ caseName, count }));

            this.analysis_countryList = Object.entries(
              precedentsAnalysis["country"]
            ).map(([country, count]) => ({ country, count }));

            this.analysis_courtsList = Object.entries(
              precedentsAnalysis["courts"]
            ).map(([country, courts]) => ({
              country,
              courts: Object.entries(courts).map(([court, count]) => ({
                court,
                count,
              })),
            }));

            this.analysis_dateRangeList = Object.entries(
              precedentsAnalysis["date_range"]
            ).map(([dateRange, count]) => ({ dateRange, count }));

            this.analysis_statueList = Object.entries(
              precedentsAnalysis["statue"]
            ).map(([statue, count]) => ({ statue, count }));
          }

          // Setup for charts and analysis
          this.setupCharts(); // Separate out chart setup to a new method

          this.all_precedents = DATA.precedents;

          // Annotation
          this.annotations =
            values.find((el) => el.key === "caselaw")?.value ?? {};
          if (Object.keys(this.annotations).length > 0) {
            this.annotations = JSON.parse(this.annotations);
          }

          // Case History
          this.caseHistoryCompletedPerCentage =
            DATA.caseHistoryCompletedPerCentage;

          this.mainCase = JSON.parse(
            values.find((el) => el.key === "main_case")?.value ?? "{}"
          );

          this.caseHistory = JSON.parse(
            values.find((el) => el.key === "case_history")?.value ?? "[]"
          );

          if (this.caseHistoryCompletedPerCentage < 100) this.loop = true;
        } else {
          console.log("DATA.values is empty. Refetching...");
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before refetching
          fetchCaseSummary(); // Refetch the case summary
        }
      } catch (error) {
        console.error("Error fetching case summary:", error);
      }
    };

    // Start fetching the case summary
    fetchCaseSummary();
  },
};
</script>

<style scoped>
.left {
  color: var(--primary);
  cursor: pointer;
}

.left p {
  font-weight: 500;
  font-size: 16px;
  color: var(--primary) !important;
}

.link p {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.link.active p {
  color: var(--primary) !important;
}

.right {
  cursor: pointer;
  background: var(--primary);
  color: #fff;
  height: 32px;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 6px;
}

.right p {
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
}
.main-summary-container {
  display: flex;
  justify-content: space-between;
  .main-summary-left {
    width: 90%;
  }
  .main-summary-right {
    width: 10%;
  }
}
/* .page-content{
    padding: 0;
} */
.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation {
  min-width: 15%;
}

.main-container {
  display: flex;
  align-items: flex-start;
}
</style>

<style scoped>
.case-summary-content {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 32px;
}

.case-title {
  color: #383a3e !important;
  font: 600 20px/28px Poppins, sans-serif !important;
}

.case-content {
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
}

.case-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 150px;
  max-width: 100%;
}

.case-info {
  margin-top: 32px;
  width: 100%;
  max-width: 816px;
  font-family: Poppins, sans-serif !important;
}

.section-header {
  color: #383a3e !important;
  font: 600 20px/1.4 Poppins, sans-serif !important;
}

.info-card {
  margin-top: 16px;
  padding: 4px 0;
}

.info-title {
  color: #383a3e !important;
  font: 500 16px/28px Poppins, sans-serif !important;
}

.info-content {
  color: #86888d !important;
  font: 400 14px/24px Poppins, sans-serif !important;
  margin-top: 4px;
}

.case-image {
  width: 100%;
  margin-top: 16px;
  object-fit: contain;
  object-position: center;
}

.judgement-info {
  margin-top: 16px;
}

.judgement-details {
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.decision-date {
  border-radius: 8px;
  background: #fafbfc;
  padding: 18px 38px;
  text-align: center;
}

.date-day {
  font: 600 32px Poppins, sans-serif !important;
  color: #383a3e !important;
}

.date-month {
  font: 500 16px/28px Poppins, sans-serif !important;
  color: #383a3e !important;
}

.date-label {
  color: #86888d;
  font: 400 14px Poppins, sans-serif !important;
  margin-top: 8px;
}

.jurisdiction-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.jurisdiction-card,
.court-card {
  border-radius: 8px;
  background: #fafbfc !important;
  padding: 8px;
}

.party-info {
  display: flex;
  margin-top: 16px;
  gap: 4px;
  flex-wrap: wrap;
}

.petitioner-info,
.respondent-info {
  width: 200px;
  padding: 4px 0;
}

.petitioner-case,
.respondent-claim {
  flex: 1;
  min-width: 240px;
}

.judgement-result {
  margin-top: 16px;
}

.case-summary {
  margin-top: 32px;
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.copy-button,
.expand-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #fff;
  padding: 2px 8px;
  cursor: pointer;
}
.copy-button {
  border: 1px solid #d1d2d5;
}
.copy-icon,
.expand-icon {
  color: var(--primary);
}

.button-text {
  color: #000046 !important;
  font: 500 14px Poppins, sans-serif !important;
}

.summary-content {
  margin-top: 16px;
}

.summary-title {
  color: #383a3e !important;
  font: 500 16px/28px Poppins, sans-serif !important;
}

.summary-text {
  color: #86888d !important;
  font: 400 14px/24px Poppins, sans-serif !important;
  margin-top: 12px;
}

.key-points-list {
  list-style-type: none;
  padding: 0;
}

.key-point {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
}

.key-point p {
  flex: 1;
  color: #86888d !important;
  font: 400 14px/24px Poppins, sans-serif !important;
}

.highlight {
  color: #383a3e !important;
}

@media (max-width: 991px) {
  .case-summary-content {
    padding: 20px;
  }

  .judgement-details,
  .party-info {
    flex-direction: column;
  }

  .decision-date {
    padding: 18px;
  }
}
</style>

<style scoped>
.dropdown-modal {
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(170, 171, 175, 0.6);
  align-self: flex-end;
  z-index: 10;
  display: flex;
  width: 239px;
  max-width: 100%;
  flex-direction: column;
  color: var(--Neutral-Black, #383a3e);
  white-space: nowrap;
  padding: 10px 8px 10px 0;
  font: 500 14px/1 Poppins, sans-serif;
}

.dropdown-option {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 12px 16px;
}

.checkbox {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Black, #383a3e);
  align-self: stretch;
  display: flex;
  width: 24px;
  height: 24px;
  margin: auto 0;
  cursor: pointer;
}

.option-text {
  align-self: stretch;
  margin: auto 0;
}

.export-button-wrapper {
  display: flex;
  width: 100%;
  padding-top: 8px;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  color: var(--Neutral-White, #fff);
  justify-content: flex-end;
}

.export-button {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  align-self: stretch;
  display: flex;
  gap: 4px;
  overflow: hidden;
  margin: auto 0;
  padding: 2px 8px;
  border: none;
  cursor: pointer;
}

.export-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
  margin: auto 0;
}

.export-text {
  align-self: stretch;
  margin: auto 0;
}

@media (max-width: 991px) {
  .dropdown-modal,
  .dropdown-option,
  .export-button-wrapper,
  .export-button {
    white-space: initial;
  }
}
#Header {
  background: #fff;
}
#export_box {
  width: 239px !important;
  height: 148px !important;
  left: -20px !important;
}
.DROPDOWN-MENU ::v-deep .tooltip-inner .dropdown-option span {
  margin-right: 0px !important;
}
</style>

<style scoped>
.case-card {
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px 10px;
}

.case-card:hover {
  background-color: #f2f3f3;

  &.highlighted {
    background-color: #e8f4ff;
  }
}

.highlighted {
  background-color: #e8f4ff;
}

.case-number {
  border-radius: 50%;
  background-color: #0e4485;
  min-height: 30px;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  font: 500 14px/30px Poppins, sans-serif;
}

.case-info-anno {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 20px;
}

.case-content-anno {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.case-header {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 12px;
}

.case-title {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.case-meta {
  display: flex;
  margin-top: 4px;
  width: 100%;
  align-items: center;
  font-size: 14px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.case-label {
  color: #86888d;
  font-weight: 400;
  line-height: 24px;
  margin-right: 10px;
}

.case-details {
  border-radius: 8px;
  border: 1px solid var(--Status-Blue, #1890ff) !important;
  background: var(--Neutral-Interface-Grey, #fafbfc) !important;
  margin-top: 16px;
  padding: 8px 16px;
}

.details-header {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Poppins, sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-title {
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.summarize-button {
  border-radius: 4px;
  background-color: var(--Primary-Blue, #0e4485) !important;
  color: var(--Neutral-White, #fff);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  border: none;
  cursor: pointer;
}

.details-content,
.topic-section,
.abstract-section {
  margin-top: 16px;
  font-family: Poppins, sans-serif;
}

.details-subtitle,
.topic-title,
.abstract-title {
  color: var(--Neutral-Black, #383a3e) !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 4px;
}

.details-text,
.topic-text,
.abstract-text {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 0 55px 0 15px;
}

.case-footer {
  display: flex;
  margin-top: 16px;
  gap: 16px;
  flex-wrap: wrap;
  padding: 0 55px 0 15px;
}

.decision-date {
  border-radius: 8px;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #383a3e;
  padding: 18px;
  font-weight: 500;
  width: 25%;
}

.date-day {
  font-size: 32px;
}

.date-month-year {
  font-size: 16px;
  line-height: 28px;
}

.date-label {
  color: #86888d !important;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.case-jurisdiction {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jurisdiction-info,
.court-info {
  border-radius: 8px;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  padding: 8px;
  align-items: center;
}

.jurisdiction-label,
.court-label {
  color: #86888d !important;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.jurisdiction-value,
.court-value {
  color: #383a3e;
  font-size: 16px;
  margin: 0;
}

.view-summary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 4px;
  color: #0e4485;
  font-weight: 500;
  line-height: 1;
  margin-top: 4px;
  padding: 2px 8px;
  background: none;
  border: none;
  cursor: pointer;
}
.bg-btn {
  background: var(--primary) !important;
  color: #fff;
  padding: 4px 16px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.jurisdiction-label,
.court-label {
  color: #86888d;
  font-size: 14px;
  margin: 0;
}

.jurisdiction-value,
.court-value {
  color: #383a3e;
  font-size: 16px;
  margin: 0;
}

.expand-btn {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.expand-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 991px) {
  .case-number {
    white-space: initial;
  }

  .case-info,
  .case-header {
    max-width: 100%;
  }
}
.details-cutoff {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.icon-text-wrapper {
  display: flex;
  align-items: center;
}

.percentage-text {
  margin-left: 8px;
}

.rotate-icon {
  display: inline-block;
  animation: rotate 1s linear infinite;
  margin-right: 8px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text-de {
  text-decoration: underline;
}
.cases {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.cases-color {
  color: var(--Status-Blue, #1890ff) !important;
  font-size: 16px;
  font-weight: 500;
}
.open-button {
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  color: #fff;
  height: 30px;
}
.report {
  justify-content: center;
  display: flex;
  width: 200px;
  align-items: center;
  height: 32px;
  background: var(--primary);
  color: #fff;
}
.pre-title {
  color: var(--primary) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
.pagination.b-pagination {
  margin: 0px;
  height: 50px !important;
}

.no-data {
  background-color: transparent;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}
</style>
